import { ThemeEnum } from '@/constants/base'
import { envIsServer } from '@/helper/env'
import { dynamicActivate, extractLocale, localeDefault } from '@/helper/i18n'

export async function onBeforeRoute(pageContext) {
  let { urlOriginal, locale, path } = pageContext
  if (!path) {
    const { urlWithoutLocale, locale: currentLocale } = extractLocale(urlOriginal)
    path = urlWithoutLocale
    locale = currentLocale || localeDefault
  }
  // 修复服务端 ssr 多语言没同步问题
  await dynamicActivate(locale)

  return {
    pageContext: {
      urlOriginal: envIsServer ? urlOriginal : path,
      path,
      locale,
    },
  }
}
